.chance h1 {
    margin-bottom: 1rem;
}

.chance .btn {
    margin-bottom: 2rem;
}

.chance .ErrorState {
    color: red;
    font-size: large;
}
