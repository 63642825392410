body {
  min-width: 500px;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-drab {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.btn-drab:hover,
.btn-drab:focus,
.btn-drab:active {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}

.btn-flex {
  display: flex;
  align-items: stretch;
  align-content: stretch;
}

.btn-flex > .btn:first-child {
  flex-grow: 1;
}

.navbar-nav .label {
  margin-left:8px;
}

.modal-container {
  position: relative;
}
.modal-container .modal, .modal-container .modal-backdrop {
  position: absolute;
}
