.queue header {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .queue header {
    flex-wrap: wrap;
  }
}

.queue-preamble {
  flex-grow: 1;
}

.queue-preamble h1 {
  margin-top: 0;
}

.queue-preamble h2 {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 2rem;
  margin-top: 0;
}

/* tween between warning colour and lighter warning colour: */
@keyframes flash-queue-paused {
    0% { background-color:#fdf6eb; }
  100% { background-color:#f0ad4e; }
}

.flash-queue-paused {
  animation: "flash-queue-paused" 600ms infinite;
}

.queue-metrics-and-actions {
  display: flex;
}

.queue-metrics {
  margin-right: 3rem;
}

.queue-metrics dl {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 1px;
  box-sizing: border-box;
  background-color: #cecece;
}

.queue-metrics dt,
.queue-metrics dd {
  display: inline-block;
  background-color: white;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  white-space: nowrap;
}

.queue-metrics dt {
  padding-right: 3rem;
}

.queue-metrics dd {
  padding-right: 3rem;
}

.queue-actions {
  display: flex;
  flex-direction: column;
}

.queue-actions > * {
  margin-bottom: 1rem;
}

.queue-data {
  width: 100%;
}

.queue-data thead {
  border-bottom: 1px solid #cecece;
}

.queue-data tbody:not(:last-child):after {
  content: "";
  margin-bottom: 15px;
  display: block;
}

.queue-data tbody:empty {
  display: none;
}

.queue-data th,
.queue-data td {
  padding: 1px 0 0;
}

.queue-data tr:first-child td {
  padding-top: 5px;
}

.DynamicQueue .queue-data-id {
  width: 50px;
}

.ScheduledQueue .queue-data-id {
  width: 100px;
}

.ScheduledQueueEntry--group-start > td {
  padding-top: 1rem;
}

.tooltip dl {
  margin: 0;
}

.slotted-queue-tabs .nav li a {
  color: #555;
}

.slotted-queue-tab {
  margin: 20px 8px 0;
}

.past-slot-start-time {
  color: #9f9f9f;
}

.slotcard-header {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 18px;
}

.slotcard-col {
  width: 25%
}

.slotcard-notes-short {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #999999;
}

.slotcard-notes-short > .label {
  margin-right:8px;
}

.slotcard-notes {
  border-left:8px solid #cccccc;
  padding:4px 16px;
  font-size:24px;
}

.slotcard-actions > .dropdown {
  margin-right:8px;
}

.slotcard-actions > button {
  margin-right:8px;
}

.slotcard-body {
  height: 0;
  overflow-y: hidden;
  padding: 0 18px;
}

.slotcard-body.slotcard-open {
  height: auto;
  overflow-y: visible;
  padding: 9px 18px;
}

.busy-info-tooltip {
  text-align: left;
}

.busy-info-tooltip .tooltip-row {
  display: flex;
  justify-content: space-between;
}

.busy-info-tooltip .tooltip-pad-bottom:not(:last-child) {
  padding-bottom: 10px;
}

.striped-slotted-seat {
  background-color: #f7f7f7;
}

.reassigned-slotted-seat {
  background-color: #fff0f0;
}

.table > tbody > tr.reassigned-slotted-seat > td {
  border-top: 1px solid #ffcccc;
}

.reassigned-note {
  font-style: italic;
}

.slotted-seat-actions-cell {
  width:40px;
  text-align:right;
}

.table > tbody > tr > td.slotted-seat-actions-padding {
  border-top:none;
}

.modal-body textarea {
  resize: vertical;
}
